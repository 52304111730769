window.__tnt || (window.__tnt = {});
__tnt.subscription || (__tnt.subscription = {});
__tnt.subscription.api || (__tnt.subscription.api = {});
__tnt.subscription.a || (__tnt.subscription.a = []);

__tnt.subscription.api.unscramble = function(sInput) {
    var sOutput = '';
    for (var i = 0, c = sInput.length; i < c; i++) {
        var nChar = sInput.charCodeAt(i);
        if (nChar >= 33 && nChar <= 126) {
            sTmp = String.fromCharCode(33 + (((nChar - 33) + 47) % 94));
            sOutput += sTmp
        } else {
            sOutput += sInput.charAt(i)
        }
    }
    return sOutput
}

window.__tnt.subscription.a.push(function(oResp){
    document.querySelectorAll(".subscriber-only.encrypted-content").forEach(function(el){ 
        el.innerHTML = __tnt.subscription.api.unscramble(el.textContent);
        el.classList.remove("encrypted-content");
    });

    if (document.getElementById('article-body')) {
        document.getElementById('article-body').dataset.contentEncrypted = 'false';
        window.dispatchEvent(new CustomEvent('tnt-subscription-content-decrypted'));
    }

    document.querySelectorAll(".subscriber-only,.subscriber-hide").forEach(function(el){ el.style.display = "" });
    __tnt.subscription.api.unscramble = function(){ return false; }
});
